import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import CardBlog from '../components/blog/card/blogCard'

// scss
import * as styles from './profile.module.scss'
// const Tabs = () => {
//   const [active, setActive] = useState(0)
//   return (
//     <ul id={styles.ProfileTab}>
//       <li
//         className={active === 0 ? styles.TabSelected : 'text-muted'}
//         onClick={() => setActive(0)}
//       > Profile </li>
//       <li
//         className={active === 1 ? styles.TabSelected : 'text-muted'}
//         onClick={() => setActive(1)}
//       > Blog Posts </li>
//     </ul>
//   )
// }

const ProfileHeader = (author) => {
  return <>
    {console.log(author)}
    <div className="container p-0">
      <header id={styles.Profile}>
        <div className="position-relative d-flex align-items-center">
          <div className="hero position-absolute d-flex justify-content-between w-100 px-5 py-4">
            <div className="d-flex align-items-center w-100">
              {/* <div className="author-image w-15">
                <Img className="rounded-circle" fluid={author.foto.localFile.childImageSharp.fluid} style={{ width: '120px' }} alt={`Foto de perfil ${author.nome}`}/>
              </div> */}
              <div className="author-name w-100">
                <div className="d-grid align-items-center">
                  <h1 className="">{author.nome}</h1>
                  <p className="text-white">{author.bio.biografia}</p>
                  {/* <span className={admin ? 'd-block' : 'd-none'}>admin</span> */}
                </div>
                <div>
                  {/* <span className="pr-4">0 Followers</span> <span>0 Following</span> */}
                </div>
              </div>
            </div>
            {/* <div className="d-flex align-items-center pt-5">
              <button type="button" className="btn btn-outline-buttonWhite py-1 px-3"> Follow </button>
            </div> */}
          </div>
          <div className="w-100">
            <GatsbyImage
              image={author.capa.localFile.childImageSharp.gatsbyImageData}
              className="img-fluid w-100" />
          </div>
        </div>
      </header>
    </div>
  </>
}

const AllPosts = (data) => {
  return (
    <div className="container p-0">
      <div className="bg-white">
        <div className="py-5 px-5">
          <header>
            <h2 className="font-size-15 text-muted mb-4">Todas as Publicações</h2>
          </header>
          <div className="row">
            {data.allPosts.nodes.map(post => {
              return (
                <div className="col-lg-6 col-md-12 mb-3">
                  <CardBlog
                    image={post.cardImage.localFile.childImageSharp.gatsbyImageData}
                    alt={post.cardImage.description}
                    description={post.description.description}
                    title={post.title}
                    author={{ nome: post.author.nome, slug: post.author.slug }}
                    readTime={post.readTime}
                    date={post.date}
                    postLink={post.slug}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const Profile = ({ data }) => {
  return (
    <>
      <section className="full bg-blue-gradient-left m-0">
        <ProfileHeader {...data.Author} />
        <AllPosts {...data} />
      </section>
    </>
  )
}

export default Profile

export const query = graphql`query ($Slug: String!) {
  Author: contentfulAuthor(slug: {eq: "time-lwart"}) {
    nome
    slug
    bio: biografia {
      biografia
    }
    foto {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 120, height: 120, layout: CONSTRAINED)
        }
      }
    }
    capa {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
  allPosts: allContentfulBlogPost(
    filter: {author: {slug: {eq: $Slug}, node_locale: {eq: "pt-BR"}}}
  ) {
    nodes {
      node_locale
      title
      slug
      description {
        description
      }
      tags
      cardImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      date(formatString: "DD/MM/YYYY")
      readTime
      author {
        nome
        slug
      }
    }
  }
}
`
// export default injectIntl(NotFoundPage)
